import React from "react"
import { Errors } from "react-redux-form"
import { Form } from "react-bootstrap"

export function validateEmail(email) {
  // eslint-disable-next-line no-control-regex
  const regexp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  return regexp.test(email.toLowerCase())
}
// TODO: change place in utils
// TODO: remove
export const ErrorList = ({ model, messages }) => (
  <Errors
    className="mt-1" 
    data-test="warning-message"
    model={model}
    show="touched"
    component={({ children }) => (
      <Form.Control.Feedback type="invalid" className="d-block">
        {children}
      </Form.Control.Feedback>
    )}
    messages={messages}
  />
)

export function validateNoSpacesInComputerName(computerName) {
  const regex = /\s/
  return !regex.test(computerName.trim())
}

export function validateNoSpecialCharAtBeginning(value) {
  const regex = /^[a-zA-Z0-9]+.*$/
  return regex.test(value.trim())
}
export function validateNoSemicolon(value) {
  const regex = /^[a-zA-Z0-9]+.*$/
  return regex.test(value.trim())
}

export function validateMachineId(machineId) {
  const MACHINE_ID_REGEXP = /^[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]-[0-9A-F][0-9A-F]$/
  return MACHINE_ID_REGEXP.test(machineId.trim())
}
